<template lang="pug">
.q-pa-dynamic
  .row.items-top.q-pb-xl.q-col-gutter-xl
    .col-12.col-md-2
      .text-h2 {{ $t('faqs') }}
    .col-12.col-md.q-my-lg.q-pt-sm
      q-list
        div(v-for="(item,key) of data" :key="item.key")
          q-expansion-item.q-py-sm(
            group="faq"
            @show="show(key)"
            :label="item.question"
            :header-class="{'text-body1':true, 'text-primary':expanded===key && !selected, 'text-accent':expanded===key && selected}")
            .q-pa-md {{item.answer}}
          q-separator(color="secondary")
</template>

<script>
  export default {
    props: ["data", "selected"],
    methods: {
      show(key) {
        this.expanded = key;
      },
    },
    data: function() {
      return {
        expanded: "0",
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/quasar.variables.scss";

  .bg-lilac {
    background-color: $lilac;
  }
</style>
