<template lang="pug">
.row
  .col
    .relative-position
      div.q-pt-lg
        q-form(@submit="onSubmit")
          .row.items-center.q-mb-sm
            .col
              FormInput(v-model="submission.name" :rules="[(val)=>val && val.length > 5 || $t('name-required')]" :name="$t('enter-your-name')")


          .row.items-center()
            .col-12
              .text-body2.q-mb-lg.text-secondary {{ $t('limitless-is-facilitated-using-chat-services-we-prefer-to-use-whatsapp-but-understand-that-you-may-only-have-access-to-an-alternative-platform') }}
            .col-6
              div(style="position:relative").q-mb-md
                
                q-select(
                    lazy-rules
                      :readonly="submission.nonwhatsapp"
                        outlined
                        v-model="submission.dialCode",
                        :options="filteredCodes",
                        map-options,
                        emit-value
                        option-value="dialCode"
                        use-input
                        @filter="filterDialCode"
                        :rules="[(val)=> validateWhatsAppCode(val) || this.$t('requires-telcode')]"
                      )
                //- q-select(
                 
                //-   :readonly="submission.nonwhatsapp"
                //-     outlined
                //-     v-model="submission.telCode",
                //-     :options="telCodes",
                //-     map-options,
                //-     option-value="dialCode"
                //-     :rules="[(val)=> validateWhatsAppCode(val) || $t('requires-telcode')]"
                //-   )
                .floatinglabel
                  .row
                    .col-auto
                      .bg-lilac.q-pa-xs.text-dark {{ $t('country') }}
            .col.q-pl-sm
              FormInput(v-model="submission.whatsapp" 
                :readonly="submission.nonwhatsapp"
                mask="############"
                :name="$t('whatsapp-number')"
                :rules="[(val)=> validateWhatsApp(val) || $t('requires-whatsapp-number')]"
              )
          .row.q-mb-md(style="margin-top:-20px;")
            .col-12
              q-checkbox(v-model="submission.nonwhatsapp" :label="$t('i-dont-have-a-whatsapp-account')")

            .col-12(v-show="submission.nonwhatsapp")
              .row().q-mt-md
                .col-12.q-mb-lg(style="position:relative;")
                  q-field(outlined :rules="[(val)=> validateService(val) || $t('service-required')]" v-model="submission.alternate")
                    template(v-slot:control)
                      q-option-group.q-mt-md(
                        v-model="submission.alternate"
                        :options="alternativeWA")
                  .floatinglabel
                    .row
                      .col-auto
                        .bg-lilac.q-pa-xs.text-dark {{ $t('select-a-service-you-use') }}
                .col-12
                  FormInput(v-model="submission.nonwhatsappident" 
                    :name="$t('user-identifier')"
                    :rules="[(val)=> validateNonWhatsApp(val) || $t('identifier-required')]"
                  )
          .row.q-mb-sm.items-center
            //- .col-2
            //-   .text-body1 Email address
            .col
              FormInput(v-model="submission.email" :name="$t('email')" :rules="[(val)=>validateEmail(val) || $t('email_address_is_required')]")

          .row.q-mb-sm.items-center
            //- .col-2
            //-   .text-body1 Language I speak
            .col
              div(style="position:relative").q-mb-md
                q-select.full-width(
                outlined
                v-model="submission.language",
                option-value="label"
                emit-value,
                map-options
                :options="languages",
                :rules="[(val) => !!val || $t('language_required')]"
                )
                .floatinglabel
                  .row
                    .col-auto
                      .bg-lilac.q-pa-xs.text-dark {{$t('your_language')}}

          .row.q-mb-sm.items-center
            //- .col-2
            //-   .text-body1 My National Society
            .col
              div(style="position:relative").q-mb-md
                q-select(
                  outlined
                  v-model="submission.region",
                  emit-value,
                  map-options
                  option-value="label"
                  :options="regions",
                  :rules="[(val) => !!val || $t('country_required')]"
                )
                
                  //- template(v-slot:selected-item="scope")
                  //-   q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
                  //-     //- q-item-section.flag(avatar) {{ scope.opt.flag }}
                  //-     q-item-section
                  //-       q-item-label {{ scope.opt.label }}

                  template(v-slot:option="scope")
                    q-item(v-bind="scope.itemProps", v-on="scope.itemEvents")
                      q-item-section.flag(avatar) {{ scope.opt.flag }}
                      q-item-section
                        q-item-label {{ scope.opt.label }}
                .floatinglabel
                  .row
                    .col-auto
                      .bg-lilac.q-pa-xs.text-dark {{$t('country_im_in')}}
          
          .row.q-mb-sm.items-center
            q-field(
              outlined,
              color="black",
              no-error-icon
              bg-color="white",
              :error="!hasAccepted"
              :bottom-slots="true"
            )
              template(v-slot:error)
                .float-right
                  p.text-white
                    q-icon(:name="matWarning" size="2em" color="white").q-mr-xs
                    span {{$t('accept_terms_error')}}
                    
              .full-width.q-py-sm
                .text-body1(style="margin-top:5px") {{ $t('consent_release') }}

                ul.q-pl-md
                  li(v-for="(message, index) in Object.values(messages.data_consent)", :key="index") {{ message }}
                
                .text-right
                  i18n(path="agree_to" tag="span")
                    span.on-left <a href="/policy" target="_blank">{{ $t('terms_privacy_policy') }}</a>
                  q-checkbox(
                    color="primary"
                    v-model="accept",
                    left-label,

                    size="lg"
                  )

                //- ul.q-pl-md
                //-   li(v-for="(message, index) in Object.values(messages.media_release_consent)", :key="index") {{ message }}
                
                //- .text-right
                //-   i18n(path="agree_to_nolink" tag="span")
                //-   q-checkbox(
                //-     color="primary"
                //-     v-model="acceptconsent",
                //-     left-label,
                //-     size="lg"
                //-   )

                .text-body1(style="margin-top:5px") {{ $t('research_subtitle') }}

                ul.q-pl-md
                  li(v-for="(message, index) in Object.values(messages.research_terms)", :key="index") {{ message }}
                
                .text-right
                  i18n(path="agree_to" tag="span")
                    span.on-left <a href="/research" target="_blank">{{ $t('terms_research_policy') }}</a>
                  q-checkbox(
                    color="primary"
                    v-model="acceptresearch",
                    left-label,
                    size="lg")
              //- template(v-slot:prepend) 
              //-   q-icon(:name="matPolicy" size="sm" color="primary")


          .row.q-mb-sm.items-center
            //- .col-2
              
            .col
              q-btn(type="submit" filled
                unelevated
                no-caps align="between" style="width:300px;max-width:80vw" square color="primary" size="lg" :icon-right="matNorthEast") {{ $t('sign-up') }}

      div.absolute-full.bg-white.text-primary(v-show="complete")
        .column.full-height.items-center.justify-center
          .col-auto.text-center
            .text-body1 {{ $t('thank-you-for-telling-us-you-are-interested-we-will-contact-you') }}
            q-icon(:name="matCheck" size="4em")

      div.absolute-full.bg-white(v-show="loading")
        .column.full-height.items-center.justify-center
          .col-auto
            q-spinner(size="4em")

</template>

<script>
  import { firebaseurl } from "@/db";
  import { ifrcList } from "ifrc-list";
  import { map, each, sortBy } from "lodash";
  const langmap = require("langmap");
  var { allCountries } = require("@/country-telephone-data-ifrc");
  let axios;
  import Icons from "@/mixins/Icons";
  import FormInput from "@/components/FormInput.vue";

  export default {
    props: ["type"],
    mixins: { Icons },
    components: { FormInput },
    // watch: {
    // "submission.telCode"() {
    //   this.submission.dialCode = this.submission.dialCode;
    // },
    // },
    methods: {
      filterDialCode(val, update) {
        if (val === "") {
          update(() => {
            this.filteredCodes = this.telCodes;
          });
          return;
        }

        update(() => {
          const needle = val.toLowerCase();
          // console.log(val);
          // console.log(this.telCodes);
          this.filteredCodes = this.telCodes.filter(
            (v) => v.label.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      validateEmail(email) {
        return /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(email);
      },
      validateService(val) {
        // console.log(val);
        // console.log(this.submission.nonwhatsapp === false || val);
        return Boolean(this.submission.nonwhatsapp === false || val);
      },
      validateNonWhatsApp(val) {
        // console.log(val);
        return this.submission.nonwhatsapp === false || val.length > 1;
      },
      validateWhatsApp(val) {
        // console.log(val);
        return (
          this.submission.nonwhatsapp === true ||
          (isFinite(val) && val.length > 1)
        );
      },
      validateWhatsAppCode(val) {
        // console.log(val);
        // return this.submission.nonwhatsapp === true || val;
        return (
          this.submission.nonwhatsapp === true ||
          (isFinite(val) && val.length > 0)
        );
      },
      async onSubmit() {
        try {
          if (this.hasAccepted !== true) {
            return;
          }

          this.loading = true;

          this.submission.type = this.type;

          if (!axios) axios = await import("axios");

          await axios.post(`${firebaseurl}/api/signup`, this.submission);

          // console.log("complete");
          this.complete = true;
        } catch (e) {
          console.error(e);
          this.$q.notify({
            type: "negative",
            message: `There was a problem submitting your signup`,
          });
        } finally {
          this.loading = false;
        }
      },
    },
    computed: {
      messages() {
        return this.$i18n.messages[this.$i18n.locale];
      },
      hasAccepted() {
        return this.accept && this.acceptresearch;
      },
      telMask() {
        return this.telCode?.format.replaceAll(".", "#");
      },
      telCodes() {
        return sortBy(
          map(allCountries, (f) => {
            return {
              ...f,
              label: `${f.name} +${f.dialCode}`,
            };
          }),
          "name"
        );
      },
      languages() {
        let tmp = [];
        each(langmap, (a, k) => {
          if (k.length == 2)
            tmp.push({
              value: k,
              label: a.nativeName,
            });
        });
        return tmp;
      },
      regions() {
        let regs = map(ifrcList, (r) => {
          return {
            value: r.code,
            flag: r.flag,
            label: r.name,
          };
        });
        // regs.splice(0, 0, {
        //   value: null,
        //   label: this.$t("all_regions"),
        //   flag: "🌍",
        // });
        return regs;
      },
    },
    data() {
      return {
        loading: false,
        complete: false,
        accept: false,
        acceptresearch: false,
        filteredCodes: [],
        submission: {
          nonwhatsapp: false,
          telCode: null,
          nonwhatsappident: "",
        },
        alternativeWA: [
          { value: "telegram", label: this.$t("telegram") },
          { value: "wechat", label: this.$t("wechat") },
        ],
      };
    },
  };
</script>
