<template lang="pug">
div.q-mb-xl
  .text-h2.q-pa-dynamic.q-mb-md {{ $t('topics-we-will-explore') }}
  q-list.q-pa-dynamic
    div(v-for="(item,key) of data" :key="item.key")
      q-expansion-item.q-my-sm(
        @show="show(key)"
        group="faq"
        :key="item.key"
        :label="item.title"
        :header-class="{'text-body1':true,'text-primary':(expanded===key && !selected), 'text-accent':(expanded===key && selected)}")
        .q-pa-md.text-body1
          ul
            li(v-for="content of item.content") {{content}}

      q-separator(:color="(color) ? color:'primary'")
</template>

<script>
  export default {
    props: ["data", "color", "selected"],
    methods: {
      show(key) {
        this.expanded = key;
      },
    },
    data: function() {
      return {
        expanded: "0",
      };
    },
  };
</script>
